import { Text } from 'slate';

const slateToHtml = (node) => {
    if (Text.isText(node)) {
        let text = node.text;

        if (node.bold) {
            text = `<strong>${text}</strong>`;
        }
        if (node.italic) {
            text = `<em>${text}</em>`;
        }
        if (node.underline) {
            text = `<u>${text}</u>`;
        }
        if (node.ejs) {
            text = `<span class="ejs">${text}</span>`;
        }

        return text;
    }

    const children = node.children.map(n => slateToHtml(n)).join('');

    switch (node.type) {
        case 'heading-one':
            return <h1>{children}</h1>;
        case 'heading-two':
            return <h2>{children}</h2>;
        case 'heading-three':
            return <h3>{children}</h3>;
        case 'heading-four':
            return <h4>{children}</h4>;
        case 'heading-five':
            return <h5>{children}</h5>;
        case 'heading-six':
            return <h6>{children}</h6>;
        case 'code':
            return `<pre><code>${children}</code></pre>`;
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'div':
            return `<div>${children}</div>`;
        case 'link':
            return `<a href="${node.url}">${children}</a>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'table':
            return `<table>${children}</table>`;
        case 'table-head':
            return `<thead>${children}</thead>`;
        case 'table-body':
            return `<tbody>${children}</tbody>`;
        case 'table-row':
            return `<tr>${children}</tr>`;
        case 'table-cell':
            return `<td>${children}</td>`;
        default:
            return children;
    }
};

export default slateToHtml;