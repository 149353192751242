import { useState } from 'react';

import Select from 'react-select';
import axiosBackend from 'core/api/backend';

import withLoaderData from 'components/withLoaderData';
import { connect } from 'react-redux';
import * as DataDelegator from "components/smart/delegator/DataDelegator";

import EjsEditor from 'pages/app/reports/ejs-editor';
import ReactJson from "react-json-view";

const AdminMassEmailEditor = (props) => {
    const [errors, setErrors] = useState([]);
    const [subject, setSubject] = useState('');
    const [group, setGroup] = useState(undefined);
    const [content, setContent] = useState('');

    const [emails, setEmails] = useState([]);

    const sendEmail = () => {
        let currentErrors = [];

        if(!group) {
            currentErrors.push("Please select a group to send the email to.");
        }

        if(!subject) {
            currentErrors.push("Please enter a subject for the email.");
        }

        if(!content) {
            currentErrors.push("Please enter the content for the email.");
        }

        setErrors(currentErrors);

        if(currentErrors.length > 0) {
            return;
        }

        props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Mass Email",
                "message": [
                    `Are you sure you want to send this email to all users in the ${group?.label} group?`,
                    "‏‏‎ ‎",
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Sending Mass Email",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        const config = {
                            method: "POST",
                            url: `/administration/send-mass-email`,
                            data: {
                                group: group?.value,
                                subject,
                                content,
                            }
                        };
                
                        axiosBackend(config)
                            .then((response) => {
                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            `The email was successfully sent to all users in the ${group?.label} group`,
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((error) => {
                                if (error.response.data.messages) {
                                    setErrors(error.response.data.messages)
                                } else {
                                    setErrors(["An error occurred while sending the email."])
                                }

                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Error",
                                        message: [
                                            "Due to an error, we were unable to send the mass email",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    const handleUserGroupChange = (selectedOption) => {
        setGroup(selectedOption);

        const config = {
            method: "POST",
            url: `/administration/preview-mass-email`,
            data: {
                group: selectedOption.value,
            }
        };

        axiosBackend(config)
            .then((response) => {
                setEmails(response.data.results)
            })
            .catch((error) => {
                if (error.response.data.messages) {
                    setErrors(error.response.data.messages)
                } else {
                    setErrors(["An error occurred while trying to preview the email."])
                }

                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Error",
                        message: [
                            "Due to an error, we were unable to preview the mass email",
                            "‏‏‎ ‎",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })

    }

    return (
        <div className='col-sm-12'>
            <div className='row'>
                <div className='col-sm-12 mb-4'>
                    <button onClick={sendEmail} className='btn btn-primary btn-sm float-right'>
                        Send Email
                    </button>
                </div>

                {errors.length > 0 && (
                    <div className='col-sm-12'>
                        <div className='alert alert-danger' role='alert'>
                            {errors.map((error, index) => (
                                <div key={index}>{error}</div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className='ibox-content mt-2'>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className='row'>


                            <div className='col-sm-12'>
                                <input onChange={(event) => setSubject(event.target.value)} type='text' className='form-control' placeholder='Subject' />
                            </div>

                            <div className='col-sm-12 mt-2'>
                                <Select
                                    value={group}
                                    onChange={handleUserGroupChange}
                                    placeholder='Select the list of users to send the email to'
                                    options={[
                                        { value: 'verified', label: 'All Verified Users' },
                                        { value: 'all-with-sites-control', label: 'All Users Assigned To A Site' },
                                        { value: 'all-with-api-keys', label: 'All Users With API Keys' },
                                        { value: 'robostack', label: 'Robostack Team' },
                                    ]}
                                />
                            </div>

                            <div className='col-sm-12 mt-2'>
                                <EjsEditor
                                    debug={true}
                                    onChange={setContent}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <ReactJson
                                    src={emails}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(AdminMassEmailEditor));