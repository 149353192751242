import axios from "axios";
import simpleAuth from "../auth/simpleAuth";

let baseURL = "http://localhost:8000"

if (process.env.NODE_ENV === "production") {
    baseURL = "https://api-next.robostack.ai"
}

if (process.env.REACT_APP_STAGING_ENV === "staging") {
    baseURL = "https://api-staging.robostack.ai"
}

const axiosBackend = axios.create({
    baseURL: baseURL,
});

axiosBackend.interceptors.request.use((config) => {
    let token = localStorage.getItem(simpleAuth.JWT_TOKEN);
    
    if(token) {
        config.headers.Authorization = token;
    }

    return config;
});

axiosBackend.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        // The authentication token is no longer valid
        localStorage.removeItem(simpleAuth.JWT_TOKEN);

        window.location = "/login";
    } 

    return Promise.reject(error);
});


export function fetchBackend(url, config) {
    let newConfig = {
        headers: {
            Authorization: localStorage.getItem(simpleAuth.JWT_TOKEN),
        }
    };

    if(config !== undefined) {
        if(config.headers) {
            newConfig.headers = {
                ...config.headers,
                ...newConfig.headers,
            }
        }

        newConfig = {
            ...config,
            ...newConfig
        };
    }
    return fetch(baseURL + url, newConfig)
}

export default axiosBackend;