import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";
import withLoaderData from "../../../../components/withLoaderData";

import { connect } from "react-redux";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import momentTimezone from "moment-timezone";

import axiosBackend from "../../../../core/api/backend";
import Select from "react-select";

import RecordViewer from "components/smart/supported/RecordViewer";
import RecordDiffViewer from "components/smart/supported/RecordDiffViewer";
import ErrorPage from "pages/error";

function ResourceHistory(props) {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [originalError, setOriginalError] = useState(null);
    const [error, setError] = useState(null);

    const [users, setUsers] = useState({});
    const [sites, setSites] = useState({});

    const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]); // Adding 1 day because the date time field will default to 00:00:00 when we compare with a date value

    const [selectedDatalogItem, setSelectedDatalogItem] = useState(null);
    const [filterDatalogEvents, setFilterDatalogEvents] = useState([]);

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setLoading(true);

        const apisToLoad = [
            axiosBackend({
                method: "POST",
                url: props.usersUrl ? props.usersUrl : `/service/resources/${props.router.params.appUUID}/${props.router.params.resourceName}/history/users`,
                data: {

                }
            }),
        ]

        if (!props.skipColumns) {
            apisToLoad.push(axiosBackend.post(`/service/resources/${props.router.params.appUUID}/${props.router.params.resourceName}/fields`),)
        }

        Promise.all(apisToLoad)
            .then(([usersFields, columnsResponse]) => {

                const applicationUsers = usersFields.data.results.reduce((acc, user) => {
                    acc[user.uuid] = user.first_name + " " + user.last_name;
                    return acc;
                }, {});

                setUsers(applicationUsers);

                if (columnsResponse) {
                    setColumns(() => {
                        const columns = columnsResponse.data.results.map((column) => {
                            column.title = column.fieldName;
                            column.id = column.fieldName;

                            return column;
                        }).sort((a, b) => {
                            if (a.displayPosition === null) {
                                return 1; // move `a` to a higher index
                            } else if (b.displayPosition === null) {
                                return -1; // move `b` to a higher index
                            } else {
                                return a.displayPosition - b.displayPosition;
                            }
                        }).filter(column => column.fieldName !== "robostack_id");

                        return columns;
                    });
                }

                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setOriginalError(error);

                if (Array.isArray(error?.response?.data?.messages)) {
                    setError(error.response.data.messages);
                } else {
                    setError(error.message);
                }

                setLoading(false);
            });
    }, [props.router.params.appUUID, props.router.params.resourceName]);

    useEffect(() => {
        setLoading(true);

        const apisToLoad = [
            axiosBackend.post(props.historyUrl ? props.historyUrl : `/service/resources/${props.router.params.appUUID}/${props.router.params.resourceName}/history`, {
                startDate,
                endDate,
            }),
        ]

        if (props.sitesUrl) {
            apisToLoad.push(axiosBackend.post(`/administration/resources/sites`));
        }

        Promise.all(apisToLoad)
            .then(([datalogResponse, sitesResponse]) => {
                setHistory([
                    ...JSON.parse(JSON.stringify(history)),
                    ...datalogResponse.data.results.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
                ]);

                if (sitesResponse) {
                    const sites = sitesResponse.data.results.reduce((acc, site) => {
                        acc[site.uuid] = site.name;
                        return acc;
                    });

                    setSites(sites);
                }

                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setOriginalError(error);

                if (Array.isArray(error?.response?.data?.messages)) {
                    setError(error.response.data.messages);
                } else {
                    setError(error.message);
                }

                setLoading(false);
            });
    }, [props.router.params.appUUID, props.router.params.resourceName, startDate, endDate]);

    const loadMoreHistory = (days) => {
        setEndDate(startDate);
        setStartDate(new Date(new Date(startDate).getTime() - days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    }

    const smallSelectStyling = {
        control: (base) => ({
            ...base,
            minHeight: 20,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        clearIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
    };

    const historyFilters = filterDatalogEvents.map((event) => event.value);

    return <>
        <div className="col ibox m-3">
            <div className="">
                {error ?
                    <>
                        <ErrorPage
                            hideSidebar={true}
                            message={error}
                            title={originalError?.response?.status === 403 ? "Permission Denied" : undefined}
                        />
                    </>
                    :
                    <div className="row">
                        <div className="col-lg-6">


                            <div className=" ">
                                <div className="ibox-content mt-0 no-top-border">

                                    <div className="row">
                                        <div className="col">
                                            <h4>90 Days Resource History</h4>
                                        </div>

                                        <div className="col text-right">
                                            <small>{momentTimezone(startDate).format("MMMM Do YYYY")} - Today</small>
                                        </div>


                                    </div>


                                    <div className="mt-2">
                                        <div className="mb-4 d-flex justify-content-end">
                                            <div className="flex-grow-1 ">
                                                <Select
                                                    value={filterDatalogEvents}
                                                    onChange={setFilterDatalogEvents}
                                                    styles={smallSelectStyling}
                                                    placeholder="Filter events shown"
                                                    options={[
                                                        {
                                                            label: "Records",
                                                            options: [
                                                                {
                                                                    badge: "CREATE",
                                                                    value: "create",
                                                                    label: "New record created",
                                                                },
                                                                {
                                                                    badge: "UPDATE",
                                                                    value: "update",
                                                                    label: "Existing record updated",
                                                                },
                                                                {
                                                                    badge: "DELETE",
                                                                    value: "delete",
                                                                    label: "Existing record deleted",
                                                                },
                                                            ].sort((a, b) => a.label.localeCompare(b.label))
                                                        },
                                                        {
                                                            label: "Resources",
                                                            options: [
                                                                {
                                                                    badge: "CREATE-RESOURCE",
                                                                    value: "create-resource",
                                                                    label: "New resource created",
                                                                },
                                                                {
                                                                    badge: "DROP",
                                                                    value: "drop",
                                                                    label: "Existing resource destroyed",
                                                                },
                                                                {
                                                                    badge: "EDIT-RESOURCE-ADD-FIELDS",
                                                                    value: "edit-resource-add-fields",
                                                                    label: "Fields added to resource",
                                                                },
                                                                {
                                                                    badge: "EDIT-RESOURCE-CHANGE-FIELDS",
                                                                    value: "edit-resource-change-fields",
                                                                    label: "Field configuration changed for resource",
                                                                },
                                                                {
                                                                    badge: "EDIT-RESOURCE-DELETE-FIELDS",
                                                                    value: "edit-resource-delete-fields",
                                                                    label: "Fields removed from resource",
                                                                },
                                                                {
                                                                    badge: "EDIT-RESOURCE-RENAMED-FIELDS",
                                                                    value: "edit-resource-renamed-fields",
                                                                    label: "Fields renamed in resource",
                                                                },
                                                            ].sort((a, b) => a.label.localeCompare(b.label))
                                                        },
                                                    ]}
                                                    isMulti
                                                />
                                            </div>
                                        </div>

                                        {history.length > 0 && <>

                                            <div className="feed-activity-list">

                                                {history.filter((item) => {
                                                    if (historyFilters.length == 0) {
                                                        return true;
                                                    }

                                                    return historyFilters.indexOf(item.operation) > -1;
                                                }).map((item, index) => {
                                                    const createdBy = users[item.createdBy] || item.createdBy;
                                                    const siteUUID = sites[item.siteUUID] || item.siteUUID;

                                                    // console.log(item)

                                                    return <div className={`feed-element m-0 pt-3 pointer template ${selectedDatalogItem?._id == item._id ? "active" : ""}`} key={index} onClick={() => setSelectedDatalogItem(item)}>

                                                        <small className="float-right">{momentTimezone(item.createdOn).fromNow()}</small>

                                                        {item.operation === "create" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-plus fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to create a record but an error occurred.<br />
                                                                </>}

                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to create a new record but the data submitted did not pass the validation rules.
                                                                    <br />
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> created a&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}/view/robostack_id/${item.dataChangeToApply[0].robostack_id}`}>
                                                                        new record
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "update" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-edit fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to edit an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}/view/robostack_id/${item.existingData[0]?.robostack_id || item.existingData?.robostack_id}`}>
                                                                        existing record
                                                                    </Link>
                                                                    but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to edit an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}/view/robostack_id/${item.existingData[0]?.robostack_id || item.existingData?.robostack_id}`}>
                                                                        existing record
                                                                    </Link>
                                                                    &nbsp;but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> edited a&nbsp;

                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}/view/robostack_id/${item.existingData[0]?.robostack_id || item.existingData?.robostack_id}`}>
                                                                        existing record
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "delete" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-trash-alt fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to delete {item.existingData.length} existing record(s) but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to delete {item.existingData.length} existing record(s) but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> deleted {item.existingData.length} existing record(s)
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "create-resource" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-database fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to create a new resource but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to create a new resource but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> created a&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        new resource
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "drop" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-trash fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to destory an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to destory an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> destroyed an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "edit-resource-add-fields" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-plus-square fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to add fields to an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to add fields to an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> added fields to&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}

                                                            </div>
                                                        </>}

                                                        {item.operation === "edit-resource-change-fields" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-wrench fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried change the field configuration of an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to change the field configuration of an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> changed the field configuration of an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "edit-resource-delete-fields" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-minus-square fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to remove fields from an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to remove fields from an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> removed fields from an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        {item.operation === "edit-resource-renamed-fields" && <>
                                                            <a className="float-left m-2">
                                                                <i className="fa fa-pencil-alt fa-2x"></i>
                                                            </a>

                                                            <div className="media-body ">
                                                                {item.operationStatus.trim() == "failure" && <>
                                                                    <strong>{createdBy}</strong> tried to rename fields of an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but an error occurred.<br />
                                                                </>}


                                                                {item.operationStatus.trim() == "error" && <>
                                                                    <strong>{createdBy}</strong> tried to rename fields of an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                    &nbsp;but the data submitted did not pass the validation rules.
                                                                </>}

                                                                {item.operationStatus.trim() == "success" && <>
                                                                    <strong>{createdBy}</strong> renamed fields of an&nbsp;
                                                                    <Link
                                                                        target="_blank"
                                                                        className="text-primary"
                                                                        to={`/app/${item.siteUUID}/resources/${item.resource}`}>
                                                                        existing resource
                                                                    </Link>
                                                                </>}

                                                                {props.sitesUrl && <p>
                                                                    on <strong>{siteUUID}</strong><br />
                                                                    for resource <strong>{item.resource}</strong>
                                                                </p>}
                                                            </div>
                                                        </>}

                                                        <div className="">
                                                            <small>
                                                                {momentTimezone(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                                <br />
                                                                Datalog ID: {item._id}
                                                            </small>
                                                        </div>

                                                    </div>
                                                })}
                                            </div>
                                        </>}

                                        {history.length == 0 && <>
                                            No history found.
                                        </>}
                                    </div>

                                    <div className="text-center mt-2">
                                        {loading && <div className="loader"></div>}

                                        <button className="btn btn-primary" onClick={() => loadMoreHistory(7)} disabled={loading}>
                                            {loading ? "Loading..." : "Load more history"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="ibox-content client-detail ">

                                <h4>Event Details</h4>
                                {selectedDatalogItem == null ? "Select an event to view details about it" :

                                    <>
                                        {props.debug ?
                                            <code><pre>{JSON.stringify(selectedDatalogItem, null, 4)}</pre></code>
                                            :
                                            <>
                                                {selectedDatalogItem.operation === "create" && <>
                                                    {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                        {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                            <div className="alert alert-danger">
                                                                {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                    const errorFields = Object.keys(error);

                                                                    return errorFields.map((errorField) => {
                                                                        return <div key={errorField}>
                                                                            {errorField}: {error[errorField].messages.join(". ")}
                                                                        </div>
                                                                    })
                                                                })}
                                                            </div>
                                                        </>}
                                                    </>}

                                                    {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                        An error occurred while trying to create this record.
                                                    </>}

                                                    {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                        <RecordViewer
                                                            columns={columns}
                                                            data={selectedDatalogItem.dataChangeToApply}
                                                            applicationUsers={users}
                                                            hidePrintButton={true}
                                                            hideEditButton={true}
                                                            hideTitle={true}
                                                        />
                                                    </>}
                                                </>}

                                                {selectedDatalogItem.operation === "delete" && <>
                                                    {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                        {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                            <div className="alert alert-danger">
                                                                {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                    const errorFields = Object.keys(error);

                                                                    return errorFields.map((errorField) => {
                                                                        return <div key={errorField}>
                                                                            {errorField}: {error[errorField].messages.join(". ")}
                                                                        </div>
                                                                    })
                                                                })}
                                                            </div>
                                                        </>}
                                                    </>}

                                                    {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                        An error occurred while trying to delete this record.
                                                    </>}

                                                    {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                        <RecordViewer
                                                            columns={columns}
                                                            data={selectedDatalogItem.existingData}
                                                            applicationUsers={users}
                                                            hidePrintButton={true}
                                                            hideEditButton={true}
                                                            hideTitle={true}
                                                        />
                                                    </>}
                                                </>}

                                                {selectedDatalogItem.operation === "update" && <>
                                                    {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                        {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                            <div className="alert alert-danger">
                                                                {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                    const errorFields = Object.keys(error);

                                                                    return errorFields.map((errorField) => {
                                                                        return <div key={errorField}>
                                                                            {errorField}: {error[errorField].messages.join(". ")}
                                                                        </div>
                                                                    })
                                                                })}
                                                            </div>
                                                        </>}
                                                    </>}

                                                    {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                        An error occurred while trying to update this record.
                                                    </>}

                                                    {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                        <RecordDiffViewer
                                                            columns={columns}
                                                            old={selectedDatalogItem.existingData}
                                                            new={selectedDatalogItem.dataChangeToApply}
                                                            applicationUsers={users}
                                                        />
                                                    </>}
                                                </>}

                                                {selectedDatalogItem.operation === "create-resource" && <>
                                                    <div className="">
                                                        This resource was created
                                                    </div>
                                                </>}

                                                {selectedDatalogItem.operation === "drop" && <>
                                                    <div className="">
                                                        {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                            {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                                <div className="alert alert-danger">
                                                                    {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                        const errorFields = Object.keys(error);

                                                                        return errorFields.map((errorField) => {
                                                                            return <div key={errorField}>
                                                                                {errorField}: {error[errorField].messages.join(". ")}
                                                                            </div>
                                                                        })
                                                                    })}
                                                                </div>
                                                            </>}
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                            An error occurred while trying to destroy this resource.
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                            This resource was destroyed
                                                        </>}
                                                    </div>
                                                </>}

                                                {selectedDatalogItem.operation === "edit-resource-add-fields" && <>
                                                    <div className="">
                                                        {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                            {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                                <div className="alert alert-danger">
                                                                    {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                        const errorFields = Object.keys(error);

                                                                        return errorFields.map((errorField) => {
                                                                            return <div key={errorField}>
                                                                                {errorField}: {error[errorField].messages.join(". ")}
                                                                            </div>
                                                                        })
                                                                    })}
                                                                </div>
                                                            </>}
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                            An error occurred while trying to add fields to this resource.
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                            {Object.keys(selectedDatalogItem.dataChangeToApply).length} fields were added to this resource<br />
                                                            <br />

                                                            Fields added:
                                                            <div className="row">
                                                                <div className="col ml-4">
                                                                    {Object.keys(selectedDatalogItem.dataChangeToApply).map((fieldName) => {
                                                                        return <div key={fieldName}>
                                                                            {fieldName}
                                                                        </div>;
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </>}

                                                {selectedDatalogItem.operation === "edit-resource-change-fields" && <>
                                                    <div className="">
                                                        {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                            {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                                <div className="alert alert-danger">
                                                                    {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                        const errorFields = Object.keys(error);

                                                                        return errorFields.map((errorField) => {
                                                                            return <div key={errorField}>
                                                                                {errorField}: {error[errorField].messages.join(". ")}
                                                                            </div>
                                                                        })
                                                                    })}
                                                                </div>
                                                            </>}
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                            An error occurred while trying to change the field configuration for this resource.
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                            Field configuration was changed for this resource and {selectedDatalogItem.existingData.resourceRecord.length} records were affected<br />
                                                            <br />

                                                            Fields changed:
                                                            <div className="row">
                                                                <div className="col ml-4">
                                                                    {Object.keys(selectedDatalogItem.dataChangeToApply).map((fieldName) => {
                                                                        return <div key={fieldName}>
                                                                            {fieldName}
                                                                        </div>;
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <br />

                                                            Records affected:
                                                            <div className="row">
                                                                <div className="col ml-4">
                                                                    {selectedDatalogItem.existingData.resourceRecord.filter((record) => record?.robostack_id !== undefined).map((record) => {
                                                                        return <div key={record.robostack_id}>
                                                                            <Link
                                                                                target="_blank"
                                                                                className="text-primary"
                                                                                to={`/app/${selectedDatalogItem.siteUUID}/resources/${selectedDatalogItem.resource}/view/robostack_id/${record.robostack_id}`}
                                                                            >
                                                                                {record.robostack_id}
                                                                            </Link>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>}

                                                    </div>
                                                </>}

                                                {selectedDatalogItem.operation === "edit-resource-delete-fields" && <>
                                                    <div className="">
                                                        {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                            {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                                <div className="alert alert-danger">
                                                                    {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                        const errorFields = Object.keys(error);

                                                                        return errorFields.map((errorField) => {
                                                                            return <div key={errorField}>
                                                                                {errorField}: {error[errorField].messages.join(". ")}
                                                                            </div>
                                                                        })
                                                                    })}
                                                                </div>
                                                            </>}
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                            An error occurred while trying to remove fields from this resource.
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                            {selectedDatalogItem.existingData.metadataRecords.length} fields were removed from this resource and {selectedDatalogItem.existingData.resourceRecord.length} records were affected<br />
                                                            <br />

                                                            Fields removed:
                                                            <div className="row">
                                                                <div className="col ml-4">
                                                                    {selectedDatalogItem.existingData.metadataRecords.map((field) => {
                                                                        return <div key={field.uuid}>
                                                                            {field.fieldName}
                                                                        </div>;
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <br />

                                                            Records affected:
                                                            <div className="row">
                                                                <div className="col ml-4">
                                                                    {selectedDatalogItem.existingData.resourceRecord.filter((record) => record?.robostack_id !== undefined).map((record) => {
                                                                        return <div key={record.robostack_id}>
                                                                            <Link
                                                                                target="_blank"
                                                                                className="text-primary"
                                                                                to={`/app/${selectedDatalogItem.siteUUID}/resources/${selectedDatalogItem.resource}/view/robostack_id/${record.robostack_id}`}
                                                                            >
                                                                                {record.robostack_id}
                                                                            </Link>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </div>

                                                        </>}
                                                    </div>
                                                </>}

                                                {selectedDatalogItem.operation === "edit-resource-renamed-fields" && <>
                                                    <div className="">
                                                        {selectedDatalogItem.operationStatus.trim() == "error" && <>
                                                            {Array.isArray(selectedDatalogItem.operationStatusDetails.errors) && <>
                                                                <div className="alert alert-danger">
                                                                    {selectedDatalogItem.operationStatusDetails.errors.map((error, index) => {
                                                                        const errorFields = Object.keys(error);

                                                                        return errorFields.map((errorField) => {
                                                                            return <div key={errorField}>
                                                                                {errorField}: {error[errorField].messages.join(". ")}
                                                                            </div>
                                                                        })
                                                                    })}
                                                                </div>
                                                            </>}
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "failure" && <>
                                                            An error occurred while trying rename fields in this resource.
                                                        </>}

                                                        {selectedDatalogItem.operationStatus.trim() == "success" && <>
                                                            Fields were renamed in this resource
                                                        </>}
                                                    </div>
                                                </>}
                                            </>}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    </>;

}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ResourceHistory));