// Import necessary libraries
import React, { useCallback, useState, useMemo } from 'react';

import { createEditor, Text, Transforms, Element as SlateElement, Editor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact } from 'slate-react';
import { jsx } from 'slate-hyperscript';

import slateToHtml from './serialization/slate-to-html';

import pretty from "pretty";

import Element from './element';
import decorateEjs from './decorate-ejs';
import EjsHighlight from './ejs-highlight';

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    Transforms.setNodes(
        editor,
        { [format]: isActive ? null : true },
        { match: n => Text.isText(n), split: true }
    );
};

const isMarkActive = (editor, format) => {
    const [match] = Array.from(
        Editor.nodes(editor, {
            match: n => n[format] === true,
            universal: true,
        })
    );
    return !!match;
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = format === 'bulleted-list' || format === 'numbered-list';

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === format,
        split: true,
    });

    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const isBlockActive = (editor, format) => {
    const [match] = Array.from(
        Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === format,
        })
    );
    return !!match;
};

const insertEjsSnippet = (editor, snippet) => {
    Transforms.insertText(editor, snippet);
};

const increaseFontSize = (editor) => {
    const [match] = Array.from(Editor.nodes(editor, { match: Text.isText }));
    if (match) {
        const { node } = match;
        const currentSize = parseInt(node.fontSize || 16, 10);  // Default to 16px if not set
        const newSize = currentSize + 2; // Increase by 2px
        Transforms.setNodes(editor, { fontSize: newSize + 'px' }, { match: Text.isText });
    }
};

const decreaseFontSize = (editor) => {
    const [match] = Array.from(Editor.nodes(editor, { match: Text.isText }));
    if (match) {
        const { node } = match;
        const currentSize = parseInt(node.fontSize || 16, 10);  // Default to 16px if not set
        const newSize = currentSize > 10 ? currentSize - 2 : 10;  // Prevent going below 10px
        Transforms.setNodes(editor, { fontSize: newSize + 'px' }, { match: Text.isText });
    }
};

const insertTable = (editor, rows, cols) => {
    const table = {
        type: 'table',
        children: [
            {
                type: 'table-body',
                children: Array.from({ length: rows }).map(() => ({
                    type: 'table-row',
                    children: Array.from({ length: cols }).map(() => ({
                        type: 'table-cell',
                        children: [{ text: '' }],
                    })),
                })),
            },
        ]
    };

    Transforms.insertNodes(editor, table);
};

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    DIV: (el) => ({ class: el.getAttribute("class"), type: 'div' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
    TABLE: () => ({ type: 'table' }),
    THEAD: () => ({ type: 'table-head' }),
    TBODY: () => ({ type: 'table-body' }),
    TR: () => ({ type: 'table-row' }),
    TD: () => ({ type: 'table-cell' }),
}
// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
}
export const deserialize = el => {
    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    } else if (el.nodeName === 'BR') {
        return '\n'
    }
    const { nodeName } = el
    let parent = el
    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
    ) {
        parent = el.childNodes[0]
    }
    let children = Array.from(parent.childNodes).map(deserialize).flat()
    if (children.length === 0) {
        children = [{ text: '' }]
    }
    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children)
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el)
        return jsx('element', attrs, children)
    }
    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el)
        return children.map(child => jsx('text', attrs, child))
    }
    return children
}

const EjsEditor = (props) => {
    const editor = useMemo(() => withReact(withHistory(createEditor())), []);  // Wrap with withHistory
    const [htmlContent, setHtmlContent] = useState('');
    const [showTableDropdown, setShowTableDropdown] = useState(false);
    const [tableSize, setTableSize] = useState({ rows: 3, cols: 3 });

    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [images, setImages] = useState([]);

    // Render the editor component.
    const renderLeaf = useCallback(props => <EjsHighlight {...props} />, []);
    const renderElement = useCallback(props => <Element {...props} />, []);

    const setAlign = (align) => {
        Transforms.setNodes(
            editor,
            { align },
            { match: n => n.type === 'paragraph' || n.type === 'heading-one' || n.type === 'heading-two' }
        );
    };

    const handleKeyDown = event => {
        if (!event.ctrlKey) return;

        switch (event.key) {
            case 'b': {
                event.preventDefault();
                toggleMark(editor, 'bold');
                break;
            }
            case 'i': {
                event.preventDefault();
                toggleMark(editor, 'italic');
                break;
            }
            case 'u': {
                event.preventDefault();
                toggleMark(editor, 'underline');
                break;
            }
            case '1': {
                event.preventDefault();
                toggleBlock(editor, 'heading-one');
                break;
            }
            case '2': {
                event.preventDefault();
                toggleBlock(editor, 'heading-two');
                break;
            }
            case '`': {
                event.preventDefault();
                toggleBlock(editor, 'code');
                break;
            }
            default:
                break;
        }
    };

    const isActiveMark = format => isMarkActive(editor, format) ? 'btn-secondary active' : '';

    const handleUndo = () => {
        Editor.undo(editor);
    };

    const handleRedo = () => {
        Editor.redo(editor);
    };



    // Function to handle the export
    const handleExport = () => {
        const html = slateToHtml(editor);
        setHtmlContent(pretty(html));

        if (props.onChange) {
            props.onChange(html);
        }
    };

    const handleSearch = async () => {
        const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${searchQuery}&client_id=${UNSPLASH_ACCESS_KEY}`
        );
        const data = await response.json();
        setImages(data.results);
    };

    const insertImage = (url) => {
        Transforms.insertNodes(editor, {
            type: 'image',
            url,
            children: [{ text: '' }],
        });
        setShowModal(false);
    };

    const UNSPLASH_ACCESS_KEY = "uvFC6Y_LiiVuoM9XD6yA-fOhPVwu2Bw6ciK4C97TQfs"

    return (
        <div className="d-flex">
            <Slate editor={editor} initialValue={[{ type: 'paragraph', children: [{ text: '' }] }]} onChange={handleExport}>
                <div className="d-flex flex-column w-100">
                    {/* Toolbar */}

                    <div className="p-2 bg-light border mb-3">
                        <div className="btn-group">
                            <button className="btn " onMouseDown={handleUndo}><i className="fa fa-undo" /></button>
                            <button className="btn " onMouseDown={handleRedo}><i className="fa fa-redo" /></button>
                        </div>

                        <div className="border-left inline border-gray"></div>


                        <div className="btn-group">
                            <div className="btn-group">
                                <button className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Heading
                                </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'heading-one'); }}><h1>Heading 1</h1></button>
                                    <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'heading-two'); }}><h2>Heading 2</h2></button>
                                    <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'heading-three'); }}><h3>Heading 3</h3></button>
                                    <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'heading-four'); }}><h4>Heading 4</h4></button>
                                    <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'heading-five'); }}><h5>Heading 5</h5></button>
                                    <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'heading-six'); }}><h6>Heading 6</h6></button>
                                </div>
                            </div>
                        </div>

                        <div className="border-left inline border-gray"></div>


                        <div className="btn-group">
                            <button className="btn " onMouseDown={e => { e.preventDefault(); increaseFontSize(editor); }}><i className="fa fa-plus" /></button>
                            <button className="btn " onMouseDown={e => { e.preventDefault(); decreaseFontSize(editor); }}><i className="fa fa-minus" /></button>
                        </div>

                        <div className="border-left inline border-gray"></div>


                        <button className="btn" onMouseDown={e => { e.preventDefault(); toggleBlock(editor, 'code'); }}>&lt;/&gt;</button>

                        <div className="border-left inline border-gray"></div>


                        <div className="btn-group">
                            <button className={`btn ${isActiveMark('bold')}`} onMouseDown={e => { e.preventDefault(); toggleMark(editor, 'bold'); }}>B</button>
                            <button className={`btn ${isActiveMark('italic')}`} onMouseDown={e => { e.preventDefault(); toggleMark(editor, 'italic'); }}>I</button>
                            <button className={`btn ${isActiveMark('underline')}`} onMouseDown={e => { e.preventDefault(); toggleMark(editor, 'underline'); }}>U</button>
                        </div>

                        <div className="border-left inline border-gray"></div>

                        <div className="btn-group">
                            <button
                                className="btn"
                                onClick={() => setShowModal(true)}
                            >
                                <i className="fa fa-image" />
                            </button>
                        </div>
                        <div className="btn-group">
                            <div className="dropdown ms-2">
                                <button
                                    className="btn dropdown-toggle"
                                    onClick={() => setShowTableDropdown(!showTableDropdown)}
                                >
                                    <i className="fa fa-table" />
                                </button>
                                {showTableDropdown && (
                                    <div className="position-absolute bg-white border p-2" style={{ zIndex: 1000 }}>
                                        {Array.from({ length: 5 }).map((_, rowIdx) => (
                                            <div key={rowIdx} className="d-flex">
                                                {Array.from({ length: 5 }).map((_, colIdx) => (
                                                    <div
                                                        key={colIdx}
                                                        className={`border p-1 ${rowIdx < tableSize.rows && colIdx < tableSize.cols ? 'bg-primary' : ''}`}
                                                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                                                        onMouseEnter={() => setTableSize({ rows: rowIdx + 1, cols: colIdx + 1 })}
                                                        onClick={() => {
                                                            insertTable(editor, rowIdx + 1, colIdx + 1);
                                                            setShowTableDropdown(false);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        ))}
                                        <small>{tableSize.rows} x {tableSize.cols} table</small>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="border-left inline border-gray"></div>


                        <div className="btn-group">
                            <button className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-align-left" />
                            </button>
                            <div className="dropdown-menu">
                                <button className="btn" onClick={() => setAlign('left')}><i className="fa fa-align-left" /></button>
                                <button className="btn" onClick={() => setAlign('center')}><i className="fa fa-align-center" /></button>
                                <button className="btn" onClick={() => setAlign('right')}><i className="fa fa-align-right" /></button>
                                <button className="btn" onClick={() => setAlign('justify')}><i className="fa fa-align-justify" /></button>
                            </div>


                        </div>

                        <div className="btn-group me-2">
                            <button
                                className="btn"
                                onMouseDown={e => {
                                    e.preventDefault();
                                    toggleBlock(editor, 'bulleted-list');
                                }}
                            >
                                <i className="fa fa-list-ul" />
                            </button>
                            <button
                                className="btn"
                                onMouseDown={e => {
                                    e.preventDefault();
                                    toggleBlock(editor, 'numbered-list');
                                }}
                            >
                                <i className="fa fa-list-ol" />
                            </button>
                        </div>

                        <div className="btn-group">
                            <button className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Insert Data Value
                            </button>
                            <div className="dropdown-menu">
                                <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); insertEjsSnippet(editor, '<%= variable %>'); }}>HTML Escaped Value</button>
                                <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); insertEjsSnippet(editor, '<%- variable %>'); }}>HTML Unescaped Value</button>

                                <div className="border-left inline border-gray"></div>

                                <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); insertEjsSnippet(editor, '<% for (let i = 0; i < 10; i++) { %>\n  Content\n<% } %>'); }}>JS For Loop</button>
                                <button className="dropdown-item" onMouseDown={e => { e.preventDefault(); insertEjsSnippet(editor, '<% items.forEach(item => { %>\n  Content\n<% }); %>'); }}>JS ForEach Loop</button>
                            </div>
                        </div>

                        <div className="border-top inline border-gray"></div>

                    </div>


                    {showModal && (
                        <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Search Unsplash</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="d-flex mb-3">
                                            <input
                                                type="text"
                                                className="form-control me-2"
                                                placeholder="Search for images..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <button className="btn btn-primary" onClick={handleSearch}>
                                                Search
                                            </button>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {images.map((image) => (
                                                <div
                                                    key={image.id}
                                                    className="m-2"
                                                    style={{ width: '100px', cursor: 'pointer' }}
                                                    onClick={() => insertImage(image.urls.small)}
                                                >
                                                    <img
                                                        src={image.urls.thumb}
                                                        alt={image.alt_description}
                                                        style={{ width: '100%', borderRadius: '4px' }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    {/* Editable area */}
                    <div className="bg-white p-3">
                        <Editable
                            renderLeaf={renderLeaf}
                            renderElement={renderElement}
                            decorate={decorateEjs}
                            placeholder="Write some text..."
                            spellCheck
                            autoFocus
                            onKeyDown={handleKeyDown}
                            style={{ outline: "0px solid transparent" }}
                        />
                    </div>
                </div>
            </Slate>

            {props.debug &&
                <div className="w-50 p-3">
                    <h5>Exported HTML</h5>
                    <textarea
                        value={htmlContent}
                        onChange={(e) => setHtmlContent(e.target.value)}
                        rows={20}
                        className="form-control"
                    />
                </div>
            }
        </div>
    );
};

export default EjsEditor;
