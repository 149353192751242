
const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align || 'left' };

    switch (element.type) {
        case 'heading-one':
            return <h1 {...attributes} style={style}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes} style={style}>{children}</h2>;
        case 'heading-three':
            return <h3 {...attributes} style={style}>{children}</h3>;
        case 'heading-four':
            return <h4 {...attributes} style={style}>{children}</h4>;
        case 'heading-five':
            return <h5 {...attributes} style={style}>{children}</h5>;
        case 'heading-six':
            return <h6 {...attributes} style={style}>{children}</h6>;
        case 'code':
            return <pre {...attributes} style={style}><code>{children}</code></pre>;
        case 'link':
            return <a {...attributes} style={style} href={element.url}>{children}</a>;
        case 'table':
            return <table {...attributes} style={style} className="table table-bordered">{children}</table>;
        case 'table-head':
            return <thead {...attributes} style={style}>{children}</thead>;
        case 'table-body':
            return <tbody {...attributes} style={style}>{children}</tbody>;
        case 'table-row':
            return <tr {...attributes} style={style}>{children}</tr>;
        case 'table-cell':
            return <td {...attributes} style={style}>{children}</td>;
        case 'image':
            return (
                <div {...attributes} contentEditable={false} className="my-3">
                    <img src={element.url} alt="Unsplash" style={{ maxWidth: '100%' }} />
                    {children}
                </div>
            );
        case 'bulleted-list':
            return <ul style={{ listStyle: "disc" }} {...attributes}>{children}</ul>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case "div": 
            console.log("div", attributes, children)
            return <p {...attributes}>{children}</p>;
        default:
            return <p {...attributes} style={style}>{children}</p>;
    }
};

export default Element;