import { Text } from 'slate';

// Highlight ejs code 
const decorateEjs = ([node, path]) => {
    const ranges = [];

    if (!Text.isText(node)) {
        return ranges;
    }

    const { text } = node;
    const regex = /<%[\s\S]*?%>/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
        ranges.push({
            anchor: { path, offset: match.index },
            focus: { path: match.index + match[0].length },
            ejs: true,
        });
    }

    return ranges;
};

export default decorateEjs;