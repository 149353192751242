import React from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import ReactJson from "react-json-view";
import Select from "react-select";

import withLoaderData from "../../../components/withLoaderData";
import axiosBackend from "../../../core/api/backend";

import SelectWithDescriptionQueryOption, { filterQueryOptionsWithDescription } from "../../../components/select-with-description/SelectWithDescriptionQueryOption";

import CreateEditor from "./create-editor";
import ReadEditor from "./read-editor";
import UpdateEditor from "./update-editor";
import AggregateEditor from "./aggregate-editor";
import DeleteEditor from "./delete-editor";

import copy from 'copy-to-clipboard';

import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import steps from "./help-steps";
import MarkIntroductionTourDone from "core/onboarding/mark-introduction-tour-done";

export const introductionIdentifier = "query-playground/index";

class QueryPlayground extends React.Component {
    constructor(props) {
        super(props);

        this.operations = [
            {
                label: "Aggregate",
                value: "aggregate",
            },
            {
                label: "Create",
                value: "create",
            },
            {
                label: "Read",
                value: "read",
            },
            {
                label: "Update",
                value: "update",
            },
            {
                label: "Delete",
                value: "delete",
            },
        ];

        this.defaultState = {
            pipeline: "[]",
            creation: "{}",
            selection: "{}",
            projection: "{}",
            updation: "{}",

            fields: [],
            results: [],

            changed: false,

            queryName: "",
            queryDescription: "",

            selectedOperation: null,
            selectedQuery: null,
            selectedResource: null,
            selectedResourceToShowFieldsFor: null,
        }

        this.state = {
            ...this.defaultState,

            queries: [],
            resources: [],

            isResultsCollapsed: true,

            status: "ready",

            showTour: false,
            stepIndex: 0,
        };

        this.handleResourceChange = this.handleResourceChange.bind(this);
        this.handleOperationChange = this.handleOperationChange.bind(this);

        this.handleResourceToShowFieldsForChange = this.handleResourceToShowFieldsForChange.bind(this);

        this.handleCreateTupleChange = this.handleCreateTupleChange.bind(this);
        this.handlePipelineChange = this.handlePipelineChange.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleUpdationChange = this.handleUpdationChange.bind(this);
        this.handleProjectionChange = this.handleProjectionChange.bind(this);
        this.handleSelectedQueryChange = this.handleSelectedQueryChange.bind(this);

        this.saveQueryFieldChanged = this.saveQueryFieldChanged.bind(this);

        this.runQuery = this.runQuery.bind(this);

        this.loadIntoEditor = this.loadIntoEditor.bind(this);

        this.toggleSaveQueryWorkFlow = this.toggleSaveQueryWorkFlow.bind(this);

        this.newQuery = this.newQuery.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
        this.deleteQuery = this.deleteQuery.bind(this);

        this.exportResultsAsJSON = this.exportResultsAsJSON.bind(this);
        this.toggleResults = this.toggleResults.bind(this);

        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);

    }

    toggleSaveQueryWorkFlow() {
        if (this.state.selectedQuery !== null) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "confirmation",
                    "title": "Confirm Save",
                    "message": [
                        "Would you like to overwrite the existing " + this.state.selectedQuery.label + " query or create a new query"
                    ],
                    yesButtonText: "Create New",
                    noButtonText: "Overwrite",
                    onNo: (onClose) => {
                        onClose();

                        this.saveQuery(true);
                    },
                    onYes: (onClose) => {
                        onClose();

                        this.setState({
                            changed: true,
                            queryName: "",
                            queryDescription: "",
                            selectedQuery: null
                        }, () => {
                            $(`#saveQueryModal`).modal({ backdrop: 'static', keyboard: false });
                        })
                    }
                },
            });
        } else {
            $(`#saveQueryModal`).modal({ backdrop: 'static', keyboard: false });
        }
    }

    saveQuery(overwrite = false) {
        const configuration = {};

        switch (this.state.selectedOperation.value) {
            case "create": {
                configuration["creation"] = JSON.parse(this.state.creation);
                break;
            }

            case "read": {
                configuration["selection"] = JSON.parse(this.state.selection);
                configuration["projection"] = JSON.parse(this.state.projection);
                break;
            }

            case "update": {
                configuration["selection"] = JSON.parse(this.state.selection);
                configuration["updation"] = JSON.parse(this.state.updation);
                break;
            }

            case "delete": {
                configuration["selection"] = JSON.parse(this.state.selection);
                break;
            }

            case "aggregate": {
                configuration["pipeline"] = JSON.parse(this.state.pipeline);
                break;
            }
        }

        const makeAPICall = () => {
            const config = {
                method: overwrite == true ? "PATCH" : "PUT",
                url: "/service/query-playground/" + this.props.router.params.appUUID,
                data: {
                    resource: this.state.selectedResource.value,
                    operation: this.state.selectedOperation.value,

                    name: this.state.queryName,
                    description: this.state.queryDescription,

                    configuration,
                }
            };

            if (overwrite == true) {
                config.data.uuid = this.state.selectedQuery.uuid;
            }

            axiosBackend(config)
                .then((response) => {
                    // console.log(response.data.results)
                    // Since this was saved, the state of the query is not changed
                    this.setState({
                        changed: false,
                    });

                    this.loadQueries((queries) => {
                        if (Array.isArray(response?.data?.results) && response.data.results.length > 0) {
                            const inserted = response.data.results[0];

                            // Tuple is only returned for create currently
                            if (inserted.tuples) {
                                const tuple = inserted.tuples[0];

                                const selectedQuery = queries.find(query => query.value == tuple.uuid);
                                this.handleSelectedQueryChange(selectedQuery);
                            }
                        }
                    });

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "success",
                            title: "Query Saved",
                            message: [
                                "Your query was saved successfully."
                            ],
                            okayButtonText: "Okay",
                        },
                    });
                })
                .catch((error) => {
                    console.error(error);
                    let messages = [
                        "Due to a server error, we were unable to save your query.",
                        "Please try again in a little while."
                    ];

                    if (error?.response?.data?.error) {
                        messages = [
                            "Please rectify the following errors and try again:",
                            "‏‏‎ ‎",
                            error.response.data.error,
                        ]
                    }

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Saving Query Error",
                            message: messages,
                            okayButtonText: "Okay"
                        },
                    });
                })
        }

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "processing",
                "title": "Saving Query",
                "message": [
                    "This might take a few seconds.",
                    "You will be shown a confirmation screen once the query is saved."
                ]
            },
        });

        $(`#saveQueryModal`).modal('hide');

        setTimeout(() => {
            makeAPICall();
        }, 2000);
    }

    deleteQuery() {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Delete",
                "message": [
                    "Are you sure you would like to delete the " + this.state.selectedQuery.label + " query?"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    const config = {
                        method: "DELETE",
                        url: "/service/query-playground/" + this.props.router.params.appUUID,
                        data: {
                            uuid: this.state.selectedQuery.value,
                        }
                    };

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Query",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once the query is deleted."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend(config)
                            .then((response) => {
                                // Since this was deleted, the state of the query is changed
                                this.setState({
                                    changed: false,
                                    selectedQuery: null,
                                }, () => {
                                    this.newQuery();
                                });

                                this.loadQueries();

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Query Deleted",
                                        message: [
                                            "Your query was deleted successfully."
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((error) => {
                                console.error(error);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Deleting Query Error",
                                        message: [
                                            "Due to a server error, we were unable to delete your query.",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 2000);
                }
            },
        });
    }

    loadIntoEditor(json, type) {
        switch (type) {
            case "create": {
                this.setState({
                    changed: true,
                    creation: json,
                });
                break;
            }

            case "read": {
                this.setState({
                    changed: true,
                    selection: json.selection,
                    projection: json.projection,
                });
                break;
            }

            case "update": {
                this.setState({
                    changed: true,
                    selection: json.selection,
                    updation: json.updation,
                });
                break;
            }

            case "delete": {
                this.setState({
                    changed: true,
                    selection: json,
                });
                break;
            }

            case "aggregate": {
                this.setState({
                    changed: true,
                    pipeline: json,
                });
                break;
            }
        }
    }

    handleOperationChange(selectedOperation) {
        this.setState({
            changed: true,
            selectedOperation,
        })
    }

    newQuery() {
        const changeQuery = () => {
            this.setState({
                ...this.defaultState,
            })
        }

        // Check if changed before doing anything
        if (this.state.changed === true) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "confirmation",
                    "title": "Unsaved Changes",
                    "message": [
                        "There are changes that were made to the currently loaded query",
                        "Are you sure you would like to discard them?"
                    ],
                    yesButtonText: "Yes",
                    noButtonText: "No",
                    onYes: (onClose) => {
                        changeQuery();
                        onClose();
                    }
                },
            });
        } else {
            changeQuery();
        }
    }

    handleSelectedQueryChange(selectedQuery) {
        const changeQuery = () => {
            const resource = this.state.resources.filter((resource) => resource.value == selectedQuery.resource).pop();
            const operation = this.operations.filter((operation) => operation.value == selectedQuery.operation).pop();

            const configuration = selectedQuery.configuration;

            if (configuration?.pipeline) {
                configuration.pipeline = JSON.stringify(configuration.pipeline, null, 4);
            }

            if (configuration?.creation) {
                configuration.creation = JSON.stringify(configuration.creation, null, 4);
            }

            if (configuration?.selection) {
                configuration.selection = JSON.stringify(configuration.selection, null, 4);
            }

            if (configuration?.projection) {
                configuration.projection = JSON.stringify(configuration.projection, null, 4);
            }

            if (configuration?.updation) {
                configuration.updation = JSON.stringify(configuration.updation, null, 4);
            }

            this.setState({
                results: [],

                changed: false,
                selectedQuery,

                queryName: selectedQuery.name,
                queryDescription: selectedQuery.description,

                selectedResourceToShowFieldsFor: resource,
                selectedResource: resource,
                selectedOperation: operation,

                ...configuration,
            }, () => {
                // So that the fields for the resource load as well
                this.loadFieldsForSelectedResource();
            })
        }

        // Check if changed before doing anything
        if (this.state.changed === true) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "confirmation",
                    "title": "Unsaved Changes",
                    "message": [
                        "There are changes that were made to the currently loaded query",
                        "Are you sure you would like to discard them?"
                    ],
                    yesButtonText: "Yes",
                    noButtonText: "No",
                    onYes: (onClose) => {
                        changeQuery();
                        onClose();
                    }
                },
            });
        } else {
            changeQuery();
        }
    }

    toggleResults() {
        this.setState((state) => {
            return {
                isResultsCollapsed: !state.isResultsCollapsed,
            }
        })
    }

    exportResultsAsJSON() {
        try {
            const results = this.state.results;
            const fileName = (this.state.selectedQuery?.label || "") + " results";

            const json = JSON.stringify(results);

            const blob = new Blob([json], { type: 'application/json' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = href;
            link.download = fileName + ".json";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.error(err);
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Export Failed",
                    message: [
                        "Due to an unexpected error, we were unable to export the results of your query.",
                        "Please try again in a little while."
                    ],
                    okayButtonText: "Okay"
                },
            });
        }
    }

    loadQueries(callback) {
        const queriesConfig = {
            method: "POST",
            url: "/service/query-playground/" + this.props.router.params.appUUID,
            data: {}
        };

        axiosBackend(queriesConfig)
            .then((response) => {
                this.setState({
                    queries: response.data.results.map((query) => {
                        query.label = query.name;
                        query.description = query.description;
                        query.value = query.uuid;
                        // query.badge = query.operation.toUpperCase();

                        return query;
                    }),
                }, () => {
                    if (typeof callback == "function") {
                        callback(this.state.queries);
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Fetching Queries List of Application Failed",
                        message: [
                            "Due to a server error, we were unable to fetch the list of queries in the given application.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, (err) => {
            this.loadQueries();

            const config = {
                method: "POST",
                url: "/service/resources/" + this.props.router.params.appUUID,
                data: {}
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        resources: response.data.results.map((resource) => {
                            resource.label = resource.name;
                            resource.value = resource.name;
                            return resource;
                        }).sort((a, b) => a.label.localeCompare(b.label)),
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Fetching Resource List of Application Failed",
                            message: [
                                "Due to a server error, we were unable to fetch the list of resources in the given application.",
                                "Please try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                })
        });

        if (this.props.router.loaderData.shouldRun) {
            this.setState({
                showTour: true,
            });
        }
    }

    loadFieldsForSelectedResource() {
        const config = {
            method: "POST",
            url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResourceToShowFieldsFor.value}/fields`,
            data: {}
        };

        axiosBackend(config)
            .then((response) => {
                this.setState({
                    fields: response.data.results.sort((a, b) => {
                        if (a.displayPosition === null) {
                            return 1; // move `a` to a higher index
                        } else if (b.displayPosition === null) {
                            return -1; // move `b` to a higher index
                        } else {
                            return a.displayPosition - b.displayPosition;
                        }
                    }),
                });
            })
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Fetching Fields List of Resources Failed",
                        message: [
                            "Due to a server error, we were unable to fetch the list of fields for the selected resource.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    handleResourceChange(selectedResource) {
        const stateToUpdate = {
            changed: true,
            selectedResource
        }

        if (this.state.selectedResourceToShowFieldsFor == null) {
            stateToUpdate.selectedResourceToShowFieldsFor = selectedResource;
        }

        this.setState(stateToUpdate, () => {
            if (stateToUpdate.selectedResourceToShowFieldsFor) {
                this.loadFieldsForSelectedResource();
            }
        });
    }

    handleResourceToShowFieldsForChange(selectedResourceToShowFieldsFor) {
        this.setState({
            changed: true,
            selectedResourceToShowFieldsFor
        }, () => {
            this.loadFieldsForSelectedResource();
        });
    }

    handlePipelineChange(json) {
        this.setState({
            changed: true,
            pipeline: json,
        });
    }

    handleCreateTupleChange(json) {
        this.setState({
            changed: true,
            creation: json,
        });
    }

    handleSelectionChange(json) {
        this.setState({
            changed: true,
            selection: json,
        });
    }

    handleProjectionChange(json) {
        this.setState({
            changed: true,
            projection: json,
        });
    }

    handleUpdationChange(json) {
        this.setState({
            changed: true,
            updation: json,
        });
    }

    runQuery() {
        if (this.state.selectedOperation?.value) {
            switch (this.state.selectedOperation?.value) {
                case "aggregate": {
                    this.runAggregation();
                    break;
                }

                case "create": {
                    this.runCreation();
                    break;
                }

                case "read": {
                    this.runRead();
                    break;
                }

                case "update": {
                    this.runUpdate();
                    break;
                }

                case "delete": {
                    this.runDelete();
                    break;
                }
            }
        }
    }

    runCreation() {
        this.setState({
            results: [],
            status: "running",
        }, () => {
            const config = {
                method: "PUT",
                url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResource.value}`,
                data: JSON.parse(this.state.creation),
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((error) => {
                    console.error(error);

                    this.setState({
                        results: error.response.data,
                        status: "error",
                    });
                })
        })
    }

    runRead() {
        this.setState({
            results: [],
            status: "running",
        }, () => {
            const config = {
                method: "POST",
                url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResource.value}`,
                data: {
                    selection: JSON.parse(this.state.selection),
                    projection: JSON.parse(this.state.projection),
                }
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((error) => {
                    console.error(error);

                    this.setState({
                        results: error.response.data,
                        status: "error",
                    });
                })
        })
    }

    runDelete() {
        this.setState({
            results: [],
            status: "running",
        }, () => {
            const config = {
                method: "DELETE",
                url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResource.value}`,
                data: {
                    selection: JSON.parse(this.state.selection)
                },
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((error) => {
                    console.error(error);

                    this.setState({
                        results: error.response.data,
                        status: "error",
                    });
                })
        })
    }

    runUpdate() {
        this.setState({
            results: [],
            status: "running",
        }, () => {
            const config = {
                method: "PATCH",
                url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResource.value}`,
                data: {
                    selection: JSON.parse(this.state.selection),
                    updation: JSON.parse(this.state.updation),
                }
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((error) => {
                    console.error(error);

                    this.setState({
                        results: error.response.data,
                        status: "error",
                    });
                })
        })
    }

    runAggregation() {
        this.setState({
            results: [],
            status: "running",
        }, () => {
            const config = {
                method: "POST",
                url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResource.value}/aggregation`,
                data: JSON.parse(this.state.pipeline)
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((error) => {
                    console.error(error);
                    
                    this.setState({
                        results: error.response.data,
                        status: "error",
                    });
                })
        })
    }

    // For the name and description of the save query
    saveQueryFieldChanged(name) {
        return (event) => {
            this.setState({
                [name]: event.target.value,
            })
        }
    }

    toggleHelp() {
        this.setState({
            showTour: true,
        });
    }

    handleJoyrideCallback(data) {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            let stateToUpdate = {
                showTour: false,
                stepIndex: 0,
            };

            if (this.props.router.loaderData.shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }

            this.setState(stateToUpdate);
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            this.setState({
                stepIndex: data.index + (data.action === ACTIONS.PREV ? -1 : 1),
            });
        }
    }

    render() {
        return (
            <div className="col-lg-12">
                <ReactJoyride
                    callback={this.handleJoyrideCallback}
                    run={this.state.showTour}
                    stepIndex={this.state.stepIndex}
                    steps={steps}
                    continuous={true}
                    showSkipButton={true}
                    showProgress={true}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                    disableScrollParentFix={true}
                />


                <div className="mt-2 mb-2">
                    <div className="row">
                        <div className="col-sm-8">
                            <button id="toggle-help" className="btn btn-gray" onClick={this.toggleHelp}>
                                <span className="far fa-question-circle fa-lg"></span>
                            </button>
                        </div>

                        <div className="col-sm-4 text-right">
                            <button onClick={this.newQuery} type="button" className="mr-2 btn btn-success">
                                <i className="fas fa-plus"></i>&nbsp;New Query
                            </button>

                            <button disabled={this.state.selectedQuery == null} onClick={this.deleteQuery} type="button" className="mr-2 btn btn-danger">
                                <i className="fas fa-times"></i>&nbsp;Delete Query
                            </button>

                            <button disabled={this.state.selectedOperation == null || this.state.selectedResource == null} onClick={this.toggleSaveQueryWorkFlow} type="button" className="mr-2 btn btn-primary">
                                <i className="fas fa-save"></i>&nbsp;Save Query
                            </button>

                            <button disabled={this.state.selectedOperation == null || this.state.selectedResource == null} onClick={this.runQuery} type="button" className="mr-2 btn btn-info">
                                <i className="fas fa-play"></i>&nbsp;Run Query
                            </button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <Select
                                components={{ Option: SelectWithDescriptionQueryOption }}
                                filterOption={filterQueryOptionsWithDescription}
                                onChange={this.handleSelectedQueryChange}
                                options={this.state.queries}
                                value={this.state.selectedQuery}
                                noOptionsMessage={() => "No existing queries found"}
                                placeholder="Select an existing query to load into the playground or fill in the details below to create a new query"
                            />
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="saveQueryModal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content animated bounceInDown">
                            <div className="modal-body">
                                <div className="text-center">
                                    <p className="h4 pb-2 text-bold">Save Query</p>
                                    <div className="row mb-4">
                                        <label>Name of your query:</label>
                                        <input defaultValue={this.state.queryName} onChange={this.saveQueryFieldChanged("queryName")} className="form-control" type="text" placeholder="Name your query" />
                                    </div>

                                    <div className="row">
                                        <label>Describe your query:</label>
                                        <textarea value={this.state.queryDescription} onChange={this.saveQueryFieldChanged("queryDescription")} rows="5" className="form-control" placeholder="Describe your query" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-start">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>

                                <button
                                    onClick={this.saveQuery}
                                    disabled={this.state.queryName == "" || this.state.queryDescription == ""}
                                    type="button"
                                    className="btn btn-primary ml-auto"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="font-bold">Fields List ({this.state.fields.length}):</label>
                                <Select
                                    onChange={this.handleResourceToShowFieldsForChange}
                                    options={this.state.resources}
                                    value={this.state.selectedResourceToShowFieldsFor}
                                />

                                {this.state.fields.length === 0 &&
                                    <div className="mt-2">Select a resource to view its fields</div>
                                }
                                <br />
                                {this.state.fields.map((field) => {
                                    return (
                                        <div className="mb-2 fieldName" key={field.fieldName}>
                                            {field.uniqueIdentifier === true ? <span><i className="fas fa-key"></i>&nbsp;</span> : undefined}

                                            <span onClick={() => copy(field.fieldName)} className="pointer">
                                                {field.fieldName}&nbsp;
                                            </span>
                                            <i onClick={() => copy(field.fieldName)} className="pointer show-on-hover far fa-copy" />
                                            {field.required === true ? <sup>*</sup> : undefined}
                                            <br />
                                            <span className="label label-default">{field.fieldType}</span>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="col-sm-6">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label className="font-bold">Run on Resource:</label>
                                        <Select
                                            onChange={this.handleResourceChange}
                                            options={this.state.resources}
                                            value={this.state.selectedResource}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <label className="font-bold">Operation:</label>
                                        <Select
                                            onChange={this.handleOperationChange}
                                            options={this.operations}
                                            value={this.state.selectedOperation}
                                        />
                                    </div>
                                </div>

                                {this.state.selectedOperation !== null && this.state.selectedOperation.value == "create" &&
                                    <div>
                                        <CreateEditor
                                            onChange={this.handleCreateTupleChange}
                                            value={this.state.creation}
                                        />
                                    </div>
                                }


                                {this.state.selectedOperation !== null && this.state.selectedOperation.value == "read" &&
                                    <div>
                                        <ReadEditor
                                            onSelectionChange={this.handleSelectionChange}
                                            onProjectionChange={this.handleProjectionChange}
                                            selection={this.state.selection}
                                            projection={this.state.projection}
                                        />
                                    </div>
                                }

                                {this.state.selectedOperation !== null && this.state.selectedOperation.value == "update" &&
                                    <div>
                                        <UpdateEditor
                                            onSelectionChange={this.handleSelectionChange}
                                            onUpdationChange={this.handleUpdationChange}
                                            selection={this.state.selection}
                                            updation={this.state.updation}
                                        />
                                    </div>
                                }

                                {this.state.selectedOperation !== null && this.state.selectedOperation.value == "delete" &&
                                    <div>
                                        <DeleteEditor
                                            onSelectionChange={this.handleSelectionChange}
                                            value={this.state.selection}
                                        />
                                    </div>
                                }

                                {this.state.selectedOperation !== null && this.state.selectedOperation.value == "aggregate" &&
                                    <div>
                                        <AggregateEditor
                                            onChange={this.handlePipelineChange}
                                            value={this.state.pipeline}
                                        />
                                    </div>
                                }
                            </div>

                            <div className="col-sm-4">
                                <label className="font-bold">Results:</label>

                                <button
                                    disabled={
                                        (
                                            this.state.selectedResource === null ||
                                            this.state.status === "running" ||
                                            Array.isArray(this.state.results) ||
                                            Object.keys(this.state.results).length == 0
                                        )
                                    }
                                    onClick={this.exportResultsAsJSON}
                                    className="btn btn-xs btn-primary float-right"
                                >
                                    <span className="fas fa-file-export"></span>&nbsp;Export JSON
                                </button>

                                <button
                                    disabled={
                                        (
                                            this.state.selectedResource === null ||
                                            this.state.status === "running" ||
                                            Array.isArray(this.state.results) ||
                                            Object.keys(this.state.results).length == 0
                                        )
                                    }
                                    onClick={this.toggleResults}
                                    className="btn btn-xs btn-success float-right mr-2"
                                >
                                    <span className={`fas ${this.state.isResultsCollapsed ? "fa-expand-alt" : "fa-compress-alt"}`}></span>&nbsp;
                                    {this.state.isResultsCollapsed ? "Expand" : "Shrink"} Results
                                </button>

                                {this.state.selectedOperation !== null &&
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <ReactJson
                                                collapsed={this.state.isResultsCollapsed}
                                                src={this.state.results}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

QueryPlayground.propsInformation = {

};

export default withLoaderData(connect(DataDelegator.mapStateToProps)(QueryPlayground));