import React, { useState } from 'react';

const EjsHighlight = ({ attributes, children, leaf }) => {
    const [showPopup, setShowPopup] = useState(false);

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.ejs) {
        return (
            <span
                {...attributes}
                className="bg-warning font-monospace position-relative"
                onMouseEnter={() => setShowPopup(true)}
                onMouseLeave={() => setShowPopup(false)}
            >
                {children}
                {showPopup && (
                    <div
                        className="position-absolute bg-white border p-2"
                        style={{ top: '100%', left: 0, zIndex: 1000, color: 'black' }}
                    >
                        Dummy content about EJS element.
                    </div>
                )}
            </span>
        );
    }

    return <span {...attributes}>{children}</span>;
};

export default EjsHighlight;