import React from "react";

import { connect } from "react-redux";
import * as DataDelegator from "../../components/smart/delegator/DataDelegator";

import moment from "moment";
import axiosBackend from "../../core/api/backend";

import DateTime from "react-datetime";

import StringToColor from "../../core/utils/stringToColor";
import ResourceHistory from "../app/resources/history/index";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);

class DatalogStackManagement extends React.Component {
    constructor(props) {
        super(props);

        this.databaseOperationColor = StringToColor(["#005f73", "#0a9396", "#94d2bd", "#e9d8a6", "#ee9b00", "#ca6702", "#bb3e03", "#ae2012", "#9b2226"]);

        this.state = {
            statistics: [],

            days: 5,
            endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // Adding 1 day because the date time field will default to 00:00:00 when we compare with a date value
            startDate: new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        }

        this.datalogStatisticsChartRef = React.createRef();
        this.changeStatisticsFilter = this.changeStatisticsFilter.bind(this);
    }

    changeStatisticsFilter(numberOfDays) {
        this.setState({

            days: numberOfDays,
            startDate: new Date(new Date().getTime() - numberOfDays * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // Adding 1 day because the date time field will default to 00:00:00 when we compare with a date value
        }, () => {
            this.loadDatalogStatistics();
        })
    }

    loadDatalogStatistics() {
        Promise.all([
            axiosBackend.post("/administration/datalog/statistics", {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            })
        ]).then(([statistics]) => {
            this.setState({
                statistics: statistics.data.results,
            });
        })
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, async () => {
            this.loadDatalogStatistics();
        });
    }

    render() {
        const options = {
            responsive: true,
            plugins: {
                datalabels: {
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold'
                    },
                    formatter: Math.round,
                    padding: 6
                }
            },
        };


        // Get all the unique dates
        const dates = this.state.statistics.reduce((acc, stat) => {
            if (!acc.includes(stat._id.date)) {
                acc.push(stat._id.date);
            }

            return acc;
        }, []).sort();

        // Split datasets by date and operation
        const datasets = this.state.statistics.reduce((acc, stat) => {
            if (!acc[stat._id.operation]) {
                acc[stat._id.operation] = {};
            }

            if (!acc[stat._id.operation][stat._id.date]) {
                acc[stat._id.operation][stat._id.date] = 0;
            }

            acc[stat._id.operation][stat._id.date] += stat.count;

            return acc;
        }, {});

        const datasetsToPlot = Object.keys(datasets).map((operation) => {
            return {
                label: operation,
                data: dates.map((date) => {
                    return datasets[operation][date]
                }),

                borderColor: this.databaseOperationColor.next(operation),
                backgroundColor: this.databaseOperationColor.next(operation, 0.5),
            }
        }, []);

        const data = {
            labels: dates,
            datasets: datasetsToPlot,
        };

        return (
            <>
                <div className="col-sm-6 mt-4">
                    <div className="ibox-title pl-4">
                        <h3><i className="fas fa-toolbox"></i>&nbsp;Datalog Statistics</h3>
                        <div className="ibox-tools">
                            <a className="collapse-link">
                                <i className="fa fa-chevron-up"></i>
                            </a>
                        </div>
                    </div>

                    <div className="ibox-content pt-0">
                        <div className="row">
                            <div className="col">
                                <Line
                                    ref={this.datalogStatisticsChartRef}
                                    options={options}
                                    data={data}
                                    onClick={(event) => {
                                        const element = getElementAtEvent(this.datalogStatisticsChartRef?.current, event);

                                        if (element.length > 0) {
                                            const datasetIndex = element[0].datasetIndex;
                                            const index = element[0].index;

                                            const logMessage = datasets[datasetIndex][index];

                                            console.log(logMessage)

                                        }
                                    }}
                                />


                                <div className="text-center mt-2">

                                    <div className="row">
                                        <DateTime
                                            timeFormat={false}
                                            value={this.state.startDate}
                                            dateFormat="YYYY-MM-DD"
                                            className="text-small"
                                            onChange={(date) => this.setState({
                                                startDate: date.toISOString().split('T')[0],
                                            }, this.loadDatalogStatistics)}
                                            
                                        />

                                        <DateTime
                                            timeFormat={false}
                                            value={this.state.endDate}
                                            dateFormat="YYYY-MM-DD"
                                            className="text-small text-right"
                                            onChange={(date) => this.setState({
                                                endDate: date.toISOString().split('T')[0],
                                            }, this.loadDatalogStatistics)}
                                        />

                                    </div>

                                    <div className="row mt-2">
                                        <button onClick={() => this.changeStatisticsFilter(1)} className={`col btn btn-xs ${this.state.days == 1 ? "btn-primary" : "btn-white"}`}>1D</button>
                                        <button onClick={() => this.changeStatisticsFilter(5)} className={`col btn btn-xs ${this.state.days == 5 ? "btn-primary" : "btn-white"}`}>5D</button>
                                        <button onClick={() => this.changeStatisticsFilter(31)} className={`col btn btn-xs ${this.state.days == 31 ? "btn-primary" : "btn-white"}`}>1M</button>
                                        <button onClick={() => this.changeStatisticsFilter(31 * 6)} className={`col btn btn-xs ${this.state.days == (31 * 6) ? "btn-primary" : "btn-white"}`}>6M</button>
                                        <button onClick={() => this.changeStatisticsFilter(moment().dayOfYear())} className={`col btn btn-xs ${this.state.days == (moment().dayOfYear()) ? "btn-primary" : "btn-white"}`}>YTD</button>
                                        <button onClick={() => this.changeStatisticsFilter(365)} className={`col btn btn-xs ${this.state.days == 365 ? "btn-primary" : "btn-white"}`}>1Y</button>
                                        <button onClick={() => this.changeStatisticsFilter(365 * 5)} className={`col btn btn-xs ${this.state.days == (365 * 5) ? "btn-primary" : "btn-white"}`}>5Y</button>
                                        <button onClick={() => this.changeStatisticsFilter(365 * 15)} className={`col btn btn-xs ${this.state.days == (365 * 15) ? "btn-primary" : "btn-white"}`}>Max</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-sm-6 mt-4">
                    <div className="ibox-title pl-4">
                        <h3><i className="fas fa-toolbox"></i>&nbsp;Datalog Viewer</h3>
                    </div>

                    <div className="ibox-content pt-0">
                        <div className="row">
                            <ResourceHistory
                                historyUrl="/administration/datalog"
                                usersUrl="/administration/resources/users"
                                sitesUrl="/administration/resources/sites"
                                skipColumns={true}
                                debug={true}
                            />
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default connect(DataDelegator.mapStateToProps)(DatalogStackManagement);