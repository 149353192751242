import React from "react";
import { connect } from "react-redux";

import { resolve, mapStateToProps } from "../../../components/smart/delegator/DataDelegator";

import ReactJson from "react-json-view";
import axiosBackend from "../../../core/api/backend";
import { Editor } from "@monaco-editor/react";
import Select from "react-select";

class SiteSeeder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceData: [],
            pipeline: [],
            results: [],

            resources: [],

            selectedApplication: null,

            status: "ready",

            value: {},
            selectedSeed: null,
            seeds: [
                {
                    label: "Add Service To Sites",
                    value: "AddServiceToAllSites",
                    config: {
                        "name": ""
                    },
                    referenceDataAPIsToLoad: [
                        {
                            method: "POST",
                            url: `/administration/resources/services`,
                            data: {
                                projection: {
                                    name: 1
                                }
                            }
                        }
                    ]
                },
                {
                    label: "Add Permission (If Not Exists) To `Unrestricted` Role - All Unmodified ",
                    value: "AddPermissionToAllUnmodifiedUnrestrictedRolesIfNotExists",
                    config: [
                        {
                            resource: '*',
                            attributes: ["*"],
                            action: "resources:history",
                            action_type: 'allow',
                        },
                    ]
                },
                {
                    label: "Add Permission (If Not Exists) To `Unrestricted` Role - All Modified and Unmodified",
                    value: "AddPermissionToAllUnrestrictedRolesIfNotExists",
                    config: [
                        {
                            resource: '*',
                            attributes: ["*"],
                            action: "resources:history",
                            action_type: 'allow',
                        },
                    ]
                },
            ]
        };

        this.runAggregation = this.runAggregation.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSeedChange = this.handleSeedChange.bind(this);
    }

    handleSeedChange(selectedSeed) {
        this.setState({
            selectedSeed,
            value: selectedSeed.config || {},
        });

        if (selectedSeed.referenceDataAPIsToLoad) {
            const promises = selectedSeed.referenceDataAPIsToLoad.map((api) => {
                return axiosBackend(api);
            });

            Promise.all(promises)
                .then((responses) => {
                    const data = responses.map((response) => {
                        return response.data;
                    });

                    this.setState({
                        referenceData: data,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Reference Data Failed",
                            message: [
                                "Due to a server error, we were unable to load the reference data.",
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                });
        } else {
            this.setState({
                referenceData: [],
            });
        }
    }

    onChange(json) {
        this.setState({
            value: JSON.parse(json),
        });
    }

    runAggregation() {
        this.setState({
            status: "running",
        }, () => {
            const config = {
                method: "POST",
                url: "/administration/run-seed",
                data: {
                    seed: this.state.selectedSeed.value,
                    config: this.state.value,
                }
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Task Pipeline Failed",
                            message: [
                                "Due to a server error, we were unable to run the task pipeline.",
                                "Please check the syntax or try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });

                    this.setState({
                        status: "error",
                    });
                })
        })
    }

    render() {
        return (
            <>
                <div className="col-sm-12 mt-4">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-sm-0">

                            </div>

                            <div className="col-sm-12">
                                <div className="row mt-4">
                                    <div className="col-sm-8">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label className="">Seed To Run:</label>
                                                <Select
                                                    isClearable
                                                    id="resource-fieldType"
                                                    placeholder="Select the seed to run"
                                                    value={this.state.selectedSeed}
                                                    onChange={this.handleSeedChange}
                                                    options={this.state.seeds}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-8">
                                                <label className="mt-4">Configuration To Pass:</label>
                                                <Editor
                                                    height="40vh"
                                                    defaultLanguage="json"
                                                    theme="vs-dark"
                                                    autoIndent={true}
                                                    onChange={this.onChange}
                                                    value={JSON.stringify(this.state.value, null, 4)}
                                                />
                                            </div>

                                            <div className="col-sm-4">
                                                <label className="mt-4">Reference Data:</label>
                                                <ReactJson
                                                    src={this.state.referenceData}
                                                />
                                            </div>
                                        </div>

                                        <button
                                            onClick={this.runAggregation}
                                            className="btn btn-primary mt-4 btn-lg"
                                        >
                                            {this.state.status == "running" ? "Running..." : "Run"}
                                        </button>
                                    </div>

                                    <div className="col-sm-4">
                                        <label className="font-bold">Results:</label>
                                        <ReactJson
                                            collapsed={false}
                                            src={this.state.results}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

SiteSeeder.propsInformation = {
};

export default connect(mapStateToProps)(SiteSeeder);